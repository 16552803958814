import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/sftsk/Sites/boonconcom/src/components/podcasts-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Intro`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/saftsaak"
      }}>{`@saftsaak`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/metzelfetz"
      }}>{`@metzelfetz`}</a></p>
    <h3>{`Frustrating banks & evil money laundering`}</h3>
    <p><a parentName="p" {...{
        "href": "https://virre.prh.fi/novus/home?userLang=en"
      }}>{`Finnish Trade Register`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/List_of_banks_in_Finland"
      }}>{`Finnish banks`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Money_laundering"
      }}>{`Money laundering`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.notaatio.fi/"
      }}>{`Translation of official documents`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.suomi.fi/suomifi/english/eservices/electronic_identification_and_digital_signature/logging_in_with_bank_identifiers/index.html"
      }}>{`Finnish signature service`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.kpmg.com/"
      }}>{`KPMG`}</a></p>
    <h3>{`Terrible war on Facebook`}</h3>
    <p><a parentName="p" {...{
        "href": "https://booncon.com/podcast/bp003-travellers/#t=1:16:48.277"
      }}>{`Finland taking a right turn`}</a>{` – `}<a parentName="p" {...{
        "href": "http://yle.fi/uutiset/finnish_mp_calls_for_fight_against_nightmare_of_multiculturalism_no_comment_from_party_leadership/8182155"
      }}>{`Immonen Facebook post`}</a>{` – `}<a parentName="p" {...{
        "href": "http://yle.fi/uutiset/diversity_rally_gathers_15000_in_helsinki/8188896"
      }}>{`Diversity rally`}</a>{` – `}<a parentName="p" {...{
        "href": "http://boonconic.com/our-helsinki/"
      }}>{`Picking Finland #1`}</a>{` – `}<a parentName="p" {...{
        "href": "http://boonconic.com/its-business-time/"
      }}>{`Picking Finland #2`}</a>{` – `}<a parentName="p" {...{
        "href": "http://yle.fi/uutiset/finns_party_to_arrange_ideology_seminars/8212200"
      }}>{`The Finns ideology seminars`}</a>{` – `}<a parentName="p" {...{
        "href": "http://boonconic.com/thoughts-on-multiculturalism-and-why-it-matters/"
      }}>{`Luki’s blog post on the topic`}</a></p>
    <h3>{`All hail to the mountain king`}</h3>
    <p><a parentName="p" {...{
        "href": "http://de.xletix.com/fotos-und-videos/xletix-challenge-tirol-2015"
      }}>{`XLETIX Challenge Tyrol`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Obstacle_racing"
      }}>{`Obstacle Racing`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=b4NVEJNv-Zo"
      }}>{`Spot Tobi doing his move`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=C4Q_s0_txrQ"
      }}>{`Team building`}</a></p>
    <h3>{`Luki pronouncing Japanese words`}</h3>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Tenkara_fishing"
      }}>{`Tenkara`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.tenkarabum.com/tenkara-rods.html"
      }}>{`Tenkara rods`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.tenkarausa.com/tenkara-techniques/"
      }}>{`Tenkara techniques`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.backcountrychronicles.com/tenkara-fly-fishing/"
      }}>{`Simple is great`}</a></p>
    <h3>{`Placing bets on alpha jokes`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.wired.com/2015/08/how-google-became-alphabet/"
      }}>{`Google is it’s own father`}</a>{` – `}<a parentName="p" {...{
        "href": "https://abc.xyz/"
      }}>{`G is for Google`}</a>{` – `}<a parentName="p" {...{
        "href": "http://techcrunch.com/2015/08/10/meet-alphabet-googles-new-corporate-boss-as-sundar-pichai-takes-over-the-search-company/"
      }}>{`Explaining technicalities`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.ft.com/cms/s/0/9a291bf8-3fa2-11e5-b98b-87c7270955cf.html"
      }}>{`Stock market strategy`}</a>{` – `}<a parentName="p" {...{
        "href": "http://uk.businessinsider.com/google-alphabet-split-eu-antitrust-not-a-factor-2015-8"
      }}>{`EU Antitrust case`}</a>{` – `}<a parentName="p" {...{
        "href": "http://techcrunch.com/2015/08/10/google-of-thrones/"
      }}>{`Loyal talent`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/alphabet/status/630851210665439233"
      }}>{`@alphabet Tweet`}</a>{` – `}<a parentName="p" {...{
        "href": "http://techcrunch.com/2015/08/12/bmw-alphabet-is-more-than-a-name-to-us/"
      }}>{`BMW pun`}</a></p>
    <h3>{`Communicating empty promises`}</h3>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Internal_communications"
      }}>{`Internal communication`}</a></p>
    <h3>{`Emails' 2nd base`}</h3>
    <p><a parentName="p" {...{
        "href": "https://basecamp.com/"
      }}>{`Basecamp`}</a></p>
    <h3>{`Slacking while channeling the hashtag`}</h3>
    <p><a parentName="p" {...{
        "href": "https://slack.com/"
      }}>{`Slack`}</a>{` – channels, not hashtags – `}<a parentName="p" {...{
        "href": "https://slack.com/integrations"
      }}>{`Integrations`}</a>{` – `}<a parentName="p" {...{
        "href": "https://github.com/"
      }}>{`Github`}</a>{` – `}<a parentName="p" {...{
        "href": "https://zendesk.com/"
      }}>{`Zendesk`}</a></p>
    <h3>{`Much more structured discourses`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.discourse.org/"
      }}>{`Discourse`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Knowledge_sharing"
      }}>{`Knowledge Sharing`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Internet_forum"
      }}>{`Forum`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Wiki"
      }}>{`Wiki`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.discourse.org/faq/"
      }}>{`Why it’s great`}</a>{` – `}<a parentName="p" {...{
        "href": "https://payments.discourse.org/buy/"
      }}>{`Discourse plans`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.digitalocean.com/community/tutorials/how-to-install-discourse-on-ubuntu-14-04"
      }}>{`Set up Discourse on Digital Ocean`}</a></p>
    <h3>{`One last thing to eat`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.restaurantday.org"
      }}>{`Restaurant day`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.facebook.com/events/1615702882005007/"
      }}>{`Buddies' Pizza Bistro`}</a></p>
    <h3>{`Outro`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com/podcast"
      }}>{`booncon.com/podcast`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/booncon"
      }}>{`@booncon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      